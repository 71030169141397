import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import '@/plugins/bootstrap-vue'
import '@/plugins/vue-html2canvas'
import '@/plugins/vue-toastification'
import '@/plugins/vue-google-adsense'
import '@/plugins/vue-gtag'
import '@/plugins/vue-textarea-autosize'

import router from './router'

new Vue({
  router,
  render: h => h(App),
  metaInfo: {
    title: '로빙고! XOXO',
    meta: [
      {
        property: 'og:title',
        content: '로빙고! XOXO😎',
        vmid: 'og:title'
      },
      {
        property: 'twitter:title',
        content: '로빙고! XOXO😎',
        vmid: 'twitter:title'
      },
      {
        property: 'og:url',
        content: 'https://ro-bingo.com/bingo',
        vmid: 'og:url'
      },
      {
        property: 'twitter:url',
        content: 'https://ro-bingo.com/bingo',
        vmid: 'twitter:url'
      },
      {
        property: 'description',
        content: '우리 모두 빙고해요😋',
        vmid: 'description'
      },
      {
        property: 'og:description',
        content: '우리 모두 빙고해요😋',
        vmid: 'og:description'
      },
      {
        property: 'twitter:description',
        content: '우리 모두 빙고해요😋',
        vmid: 'twitter:description'
      },
      {
        property: 'og:image',
        content: 'https://ro-bingo-static.s3.ap-northeast-2.amazonaws.com/robingo.png',
        vmid: 'og:image'
      },
      {
        property: 'twitter:image',
        content: 'https://ro-bingo-static.s3.ap-northeast-2.amazonaws.com/robingo.png',
        vmid: 'twitter:image'
      },
      {
        property: 'og:site_name',
        content: '로빙고! XOXO😎',
        vmid: 'og:site_name'
      },
      {
        property: 'og:type',
        content: 'website',
        vmid: 'og:type'
      }
    ]
  }
}).$mount('#app')
