<template>
  <div class="cell-wrapper">
    <a class="cell"
       :href="$router.resolve(route).href"
       ontouchstart>
      <h4>{{ title }}</h4>
      <h6>{{ subtitle }}</h6>
    </a>
  </div>
</template>

<script>

export default {
  name: "LinkButton",

  props: {
    route: Object,
    title: String,
    subtitle: String
  }
}
</script>

<style scoped lang="scss">
.cell {
  display: block;
  padding: 20px 20px;
  font-family: Bazzi;

  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius: 15px 255px;
  border-top: solid 2px #41403E;
  border-left: solid 2px #41403E;
  border-right: solid 2px #41403E;
  transition: ease-in-out .1s;
  -webkit-transition: ease-in-out .1s;
  background-color: rgba(255, 255, 255, .4);

  &:last-child {
    border-bottom: solid 2px #41403E;
  }

  h4 {
    color: black;
  }

  h6 {
    color: rgba(0, 0, 0, .7);
    text-decoration-line: underline;
  }

  &:active {
    border-bottom: solid 2px #41403E;
    background-color: rgba(255, 255, 0, .7);
    transform: skewX(-10deg) skewY(10deg);
    -webkit-transform: skewX(-10deg) skewY(10deg);
  }
}
</style>
