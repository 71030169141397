<template>
  <div class="container px-0">
    <div class="builder"
         v-if="!blobUrl">
      <div class="print-area" ref="printArea">
        <div class="title text-center">
          <h3>🧺2023 버킷리스트🧺</h3>
        </div>
        <div class="signature">
          <span>https://ro-bingo.com/2023</span>
        </div>
        <div class="form-wrapper">
          <div class="row">
            <div class="col py-0">
              <div class="form-group mb-0">
                <label>✈️ 여행</label>
                <textarea-autosize class="input-block" type="text" contenteditable
                                   placeholder="2023년에는 어디로 여행을 가고 싶나요?"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col py-0">
              <div class="form-group mb-0">
                <label>😴 여가생활</label>
                <textarea-autosize class="input-block" type="text" contenteditable placeholder="2023년에는 어떻게 여가시간을 보내고 싶나요?
새로운 취미 만들어 볼까요?
아니면 하고 있는 취미를 더 열심히 해볼끼요?"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col py-0">
              <div class="form-group mb-0">
                <label>💪 새로운 도전</label>
                <textarea-autosize class="input-block" type="text" contenteditable placeholder="2023년의 도전해 보고 싶은 것이 있나요?
연애 시작? 이직? 무엇이든 도전해봅시다!"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col py-0">
              <div class="form-group mb-0">
                <label>🛠 고치고 싶은 것</label>
                <textarea-autosize class="input-block" type="text" contenteditable placeholder="2023년 갓생을 살기 위해 고치고 싶은 것은 무엇인가요?
과소비? 음주? 뭐든지 적어봅시다"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="button text-center" @click.once="createImage" ontouchstart>
          <div class="d-flex flex-column align-items-center" v-if="processing">
            <b-spinner variant="warning" type="grow" label="Spinning"/>
            <span>이미지 만드는중!</span>
          </div>
          <template v-else>
            <ph-instagram-logo :size="24"/>
            <span>인스타 스토리에 올리기!</span>
          </template>
        </div>
      </div>
    </div>
    <div class="result" v-if="blobUrl">
      <div class="title text-center">
        <h3>🥳</h3>
        <span>사진을 저장하고 인스타그램 스토리에 공유해보세요!</span>
      </div>
      <div class="image-area">
        <img :src="blobUrl"/>
        <div class="save-indicator" style="pointer-events: none">
          <p>꾹눌러<br>사진첩에 저장해요</p>
        </div>
        <a href="instagram://story-camera" class="instagram-button text-center">
          <ph-instagram-logo :size="24"/>
          <span>인스타 열기</span>
        </a>
      </div>

      <div class="actions">
        <div class="button text-center" @click="blobUrl=undefined" ontouchstart>
          <ph-image-square :size="24"/>
          <span>다시 만들기</span>
        </div>
        <div class="button text-center" @click="copyUrlToClipBoard" ontouchstart>
          <ph-link-simple :size="24"/>
          <span>링크 복사</span>
        </div>
        <div class="button text-center" @click="goToRandomBingo" ontouchstart>
          <ph-shuffle :size="24"/>
          <span>랜덤 빙고</span>
        </div>
      </div>
    </div>
    <bingo-list/>
  </div>
</template>

<script>
import bingoData from '@/api/bingo.json'
import {intersection, includes, find, sample, shuffle} from "lodash"
import {PhImageSquare, PhLinkSimple, PhShuffle, PhInstagramLogo} from 'phosphor-vue'
import domtoimage from 'dom-to-image'
import {POSITION, TYPE} from 'vue-toastification'
import Ad from "@/components/Ad";
import BingoList from "@/pages/BingoList/index";

export default {
  name: "index",

  metaInfo() {
    return {
      title: '로빙고! | 갓생 2023😎',
      link: [
        {rel: 'canonical', href: `https://ro-bingo.com/2023`}
      ],
      meta: [
        {
          property: 'og:title',
          content: '굿바이 2020',
          template: chunk => `로빙고! | ${chunk}`,
          vmid: 'og:title'
        },
        {
          property: 'twitter:title',
          content: '굿바이 2020',
          template: chunk => `로빙고! | ${chunk}`,
          vmid: 'twitter:title'
        },
        {
          property: 'og:url',
          content: '2021',
          template: chunk => `https://ro-bingo.com/${chunk}`,
          vmid: 'og:url'
        },
        {
          property: 'twitter:url',
          content: '2021',
          template: chunk => `https://ro-bingo.com/${chunk}`,
          vmid: 'twitter:url'
        },
        {
          property: 'description',
          content: '굿바이 2020! 2021년 어서오고!',
          vmid: 'description'
        },
        {
          property: 'og:description',
          content: '굿바이 2020! 2021년 어서오고!',
          vmid: 'og:description'
        },
        {
          property: 'twitter:description',
          content: '굿바이 2020! 2021년 어서오고!',
          vmid: 'twitter:description'
        }
      ]
    }
  },

  components: {BingoList, Ad, PhImageSquare, PhLinkSimple, PhShuffle, PhInstagramLogo},

  data() {
    return {
      blobUrl: null,
      processing: false,
      bingoData
    }
  },

  methods: {
    createImage() {
      this.processing = true
      this.convertDomToBlob()
    },

    convertDomToBlob() {
      const el = this.$refs.printArea
      const scale = 750 / el.offsetWidth

      domtoimage.toPng(el, {
        height: el.offsetHeight * scale,
        width: el.offsetWidth * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
          width: el.offsetWidth + "px",
          height: el.offsetHeight + "px"
        }
      }).then(() => {
        domtoimage.toBlob(el, {
          height: el.offsetHeight * scale,
          width: el.offsetWidth * scale,
          style: {
            transform: "scale(" + scale + ")",
            transformOrigin: "top left",
            width: el.offsetWidth + "px",
            height: el.offsetHeight + "px"
          }
        }).then(blob => {
          this.blobUrl = URL.createObjectURL(blob)
          this.processing = false
        })
      })
    },

    copyUrlToClipBoard() {
      this.$gtag.event('copy-url', {'event_category': 'button'})
      const el = document.createElement('textarea')
      el.value = window.location.href
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.showToast('클립보드에 링크가 복사되었어요 😎')
    },

    showToast(msg) {
      this.$toast(msg, {
        type: TYPE.INFO,
        position: POSITION.BOTTOM_CENTER,
        timeout: 1000,
        transition: "Vue-Toastification__bounce",
        closeButton: false
      })
    },

    goToRandomBingo() {
      this.$gtag.event('load-random-bingo', {'event_category': 'button'})
      const id = sample(bingoData.filter(data => data.id !== parseInt(this.$route.params.id))).id
      window.location.href = this.$router.resolve({name: 'Bingo', params: {id}}).href
    }
  }
}
</script>

<style scoped lang="scss">
@import "~papercss/src/styles";

.container {
  max-width: 500px;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-bottom: 20px;
  background: url("~@/assets/images/geometry2.png");
}

.print-area {
  padding: 20px;
  background: url("~@/assets/images/geometry2.png");
}

.signature {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 0 20px 0;

  span {
    font-family: Bazzi;
    font-size: 20px;
    text-align: right;
  }
}

.form-wrapper {
  * {
    font-family: Bazzi;
  }

  label {
    font-weight: bold;
    font-size: 20px;
    width: 100%;

    border-bottom: 2px dotted gray;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  textarea:placeholder-shown {
    border-color: $dark-warning;
    -webkit-appearance: none;
  }

  textarea:not(:placeholder-shown) {
    border: none;
    font-size: 20px;
    color: black;
    padding: 0;
  }
}

.title {
  width: 100%;
  padding: 20px 0;
  color: black;

  h2, h3, h4, span {
    font-family: Yangjin;
  }
}

.actions {
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;

  .button {
    width: 100%;
    cursor: pointer;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius: 15px 255px;
    border: solid 2px #41403E;
    color: rgba(0, 0, 0, .8);
    padding: 20px 5px;
    justify-items: center;
    align-items: center;
    transition: all .5s;
    -webkit-transition: all .5s;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:active {
      background-color: rgba(255, 255, 0, .7);
      transform: skewY(-20deg);
      -webkit-transform: skewY(-20deg);
    }

    svg {
    }

    span {
      font-family: Bazzi;
      font-size: 14px;
    }
  }
}

.image-area {
  display: grid;
  grid-template-columns: 65% auto;
  grid-gap: 1rem;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 30px;
  place-items: center;

  img {
    grid-area: 1/1/2/2;
    box-shadow: 20px 20px 34px -26px rgba(0, 0, 0, .4);
    border-width: 4px;
    border-color: rgba(0, 0, 0, .8);
    border-style: dashed;
    padding: 15px;
  }

  .save-indicator {
    grid-area: 1/1/2/2;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform-origin: center center;
    -webkit-transform-origin: center center;
    background-color: rgba(255, 255, 0, .4);
    text-align: center;
    padding: 5px 15px;
    margin: 0;
    pointer-events: none;
    touch-action: none;

    p {
      font-size: 18px;
      color: rgba(196, 22, 22, 0.6);
      font-weight: bold;
      font-family: Bazzi;
      -webkit-animation: vibrate-1 0.5s linear infinite both;
      animation: vibrate-1 0.5s linear infinite both;
      pointer-events: none;
      touch-action: none;
    }
  }

  .instagram-button {
    width: 100%;
    cursor: pointer;
    background-image: none;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius: 15px 255px;
    border: solid 2px #41403E;
    color: rgba(0, 0, 0, .8);
    padding: 20px 5px;
    justify-items: center;
    align-items: center;
    transition: all .5s;
    -webkit-transition: all .5s;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:active {
      background-color: rgba(255, 255, 0, .7);
      transform: skewY(-20deg);
      -webkit-transform: skewY(-20deg);
    }

    svg {
    }

    span {
      font-family: Bazzi;
      font-size: 14px;
    }
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-12-31 3:19:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

</style>
