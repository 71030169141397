<template>
  <div class="addthis--wrapper">
    <div class="addthis_inline_share_toolbox"/>
  </div>
</template>

<script>
export default {
  name: "Sharer"
}
</script>

<style scoped lang="scss">
.addthis--wrapper {
  p {
    text-align: center;
    color: #00473e;
    font-family: Bazzi;

  }
}

.addthis_inline_share_toolbox {
  padding-bottom: 20px;

  ::v-deep .at-resp-share-element .at-share-btn-elements {
    display: grid;
    grid-auto-flow: column;
    gap: .25rem;
    justify-content: center;


    a.at-icon-wrapper {
      cursor: pointer;
      border-top-left-radius: 255px 15px !important;
      border-top-right-radius: 15px 225px !important;
      border-bottom-right-radius: 225px 15px !important;
      border-bottom-left-radius: 15px 255px !important;
      border: solid 2px #41403E;
      color: rgba(0, 0, 0, .8);
      box-shadow: none;
      padding: .5rem;
      margin: 0;
      transition: all .5s;
      -webkit-transition: all .5s;

      &:hover {
        transform: none;
      }

      &:active {
        background-color: rgba(255, 255, 0, .7);
        transform: skewY(-20deg);
        -webkit-transform: skewY(-20deg);
      }

    }
  }
}

</style>
