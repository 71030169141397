import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import BingoList from '@/pages/BingoList'
import Bingo from '@/pages/Bingo'
import Newlife2023 from '@/pages/Newlife2023'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: BingoList
  },
  {
    path: '/bingo',
    name: 'BingoList',
    component: BingoList
  },
  {
    path: '/bingo/:id',
    name: 'Bingo',
    component: Bingo
  },
  {
    path: '/2023',
    name: 'Newlife2023',
    component: Newlife2023
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
