<template>
  <div :class="isActive ? 'active': ''" @click="toggle(index)">
    <span><slot/></span>
    <div class="highlight" v-if="isActive"/>
  </div>
</template>

<script>
export default {
  name: "Cell",

  props: {
    index: {
      type: Number
    }
  },

  data() {
    return {
      isActive: false
    }
  },

  methods: {
    toggle(index) {
      this.isActive = !this.isActive
      const eventName = this.isActive ? 'select' : 'unselect'
      this.$emit(eventName, index)
    }
  },

  watch: {
    '$route.params.id'() {
      this.isActive = false
    }
  }
}
</script>

<style scoped>
span {
  grid-area: cell;
  font-size: 14px;
  line-height: 1;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  padding: 2px 2px;
  font-family: Bazzi;
  z-index: 20;
}

div.highlight {
  grid-area: cell;
  width: 100%;
  height: 12px;
  transform: skew(25deg) rotate(-15deg);
  background: -webkit-linear-gradient(rgba(255, 255, 0, .1), rgba(255, 255, 0, .5));
}
</style>