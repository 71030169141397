<template>
  <div class="container py-3">
    <h2 class="text-center pt-3 pb-1">뭐해? 내생각해?🥰</h2>
    <h4 class="text-center pb-3">ro-bingo.com</h4>
    <div class="pb-5 list--wrapper">
      <div class="title text-center">
        <h5>🌅 2023년은 갓생 산다 🌅</h5>
      </div>
      <LinkButton :route="{name: 'Newlife2023'}" title="🧺2023 버킷리스트🧺" subtitle="버킷리스트 예쁘게 적어서 인스타에 올려버리기"/>
    </div>
    <div class="pb-5 list--wrapper">
      <div class="title text-center">
        <h5>🥳 새 빙고 나왔따! 🥳</h5>
      </div>
      <List :bingo-data="BingoData.filter(bingo =>  bingo.id === 22 )"/>
    </div>
    <div class="pb-5 list--wrapper">
      <div class="title text-center">
        <h5>v😎v</h5>
      </div>
      <List :bingo-data="shuffledBingoData" :ad-repeat="3"/>
    </div>
  </div>
</template>

<script>
import BingoData from '@/api/bingo.json'
import List from '@/pages/BingoList/List'
import {shuffle} from 'lodash'
import Ad from "@/components/Ad";
import LinkButton from "@/pages/BingoList/LinkButton";

export default {
  name: "BingoList",

  components: {LinkButton, Ad, List},

  metaInfo() {
    return {
      link: [
        {rel: 'canonical', href: `https://ro-bingo.com/bingo`}
      ],
      meta: [
        {
          property: 'og:url',
          content: 'https://ro-bingo.com/bingo',
          vmid: 'og:url'
        },
        {
          property: 'twitter:url',
          content: 'https://ro-bingo.com/bingo',
          vmid: 'twitter:url'
        }
      ]
    }
  },


  data() {
    return {
      BingoData
    }
  },

  computed: {
    shuffledBingoData() {
      return shuffle(this.BingoData);
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/geometry2.png");

  h2 {
    font-family: Yangjin;
  }

  h4 {
    font-family: Bazzi;
  }
}

.list--wrapper {
  .title {
    font-family: Bazzi;
  }
}

</style>
