<template>
  <div style="display: flex; justify-content: center">
    <ins v-html="html"/>
  </div>
</template>

<script>
// https://stackoverflow.com/questions/45561612/ads-with-script-tags-in-template-vue-js
// https://github.com/TheDynomike/vue-script-component
export default {
  name: "Ad",

  props: {
    adContext: Object
  },

  data() {
    return {
      html: null
    }
  },

  methods: {
    adFitHtml(adUnit, width, height) {
      return `<ins class="kakao_ad_area" style="display:none;" data-ad-unit="${adUnit}" data-ad-width="${width}" data-ad-height="${height}"></ins>`
    },
    coupangHtml() {

    }
  },

  mounted() {
    this.$nextTick(function () {
      const adContext = this.$props.adContext
      switch (adContext.type) {
        case 'adFit':
          this.html = this.adFitHtml(adContext.adUnit, adContext.width, adContext.height)
          break
        case 'coupang':
          break
        default:
          break
      }
    })
  },
}
</script>

<style scoped>

</style>
