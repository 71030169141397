<template>
  <div class="container px-0" :style="computedVars">
    <div class="print-area" ref="printArea">
      <div class="title text-center">
        <h2>{{ bingoTitle }}</h2>
        <h5>{{ bingoSubtitle }}</h5>
      </div>
      <div class="signature">
        <span>ro-bingo.com{{ $route.fullPath.split('?')[0] }}</span>
      </div>
      <div class="bingo" ref="bingo">
        <div class="bingo--board">
          <Cell class="bingo--cell justify-content-center align-items-center text-center"
                v-for="(elem, index) in bingoElements"
                @select="selectCell"
                @unselect="unselectCell"
                :style="`grid-row-start: ${parseInt(index/5) + 1}; grid-row-end: ${parseInt(index/5) + 2};
                       grid-column-start: ${index%5 + 1}; grid-column-end: ${index%5 + 2};`"
                :key="`bingoCell-${index}`" :index="index"
                ontouchstart>
            {{ elem }}
          </Cell>
          <div class="bingo--diagonal-line bingo--diagonal-line-left" v-if="leftMatched"/>
          <div class="bingo--diagonal-line bingo--diagonal-line-right" v-if="rightMatched"/>
          <template v-for="row in matchedRows">
            <div :class="`bingo--row-line bingo--row-line-${row}`" :key="`matchedRow-${row}`"/>
          </template>
          <template v-for="col in matchedCols">
            <div :class="`bingo--col-line bingo--col-line-${col}`" :key="`matchedCol-${col}`"/>
          </template>
        </div>
      </div>
    </div>
    <sharer/>
    <div class="actions">
      <div class="button" @click="$router.push({name: 'Newlife2023'})">
        <ph-hand-waving :size="24"/>
        <span>2023<br>버킷리스트</span>
      </div>
      <div class="button" @click="copyUrlToClipBoard" ontouchstart>
        <ph-link-simple :size="24"/>
        <span>링크 복사</span>
      </div>
      <div class="button" @click="goToRandomBingo" ontouchstart>
        <ph-shuffle :size="24"/>
        <span>랜덤 빙고</span>
      </div>
    </div>
    <div class="px-2 pb-5">
      <div class="title text-center">
        <h5>🥳 새 빙고 나왔따! 🥳</h5>
      </div>
      <List :bingo-data="newBingo" :expose-more-btn="true" :ad-repeat="3"/>
    </div>
    <Ad :adContext="{type: 'adFit', adUnit: 'DAN-9qhE26msfSs2KHH3', width: 300, height: 250}"/>
    <div class="px-2 pb-5">
      <div class="title text-center">
        <h5>👇 이것도 존잼이야 👇</h5>
      </div>
      <List :bingo-data="featuredBingo" :expose-more-btn="true" :ad-repeat="3"/>
    </div>
  </div>
</template>

<script>
import bingoData from '@/api/bingo.json'
import Cell from "@/pages/Bingo/Cell"
import List from "@/pages/BingoList/List"
import {intersection, includes, find, sample, shuffle} from "lodash"
import {PhImageSquare, PhLinkSimple, PhHeadphones, PhShuffle, PhHandWaving} from 'phosphor-vue'
import domtoimage from 'dom-to-image'
import FileSaver from 'file-saver'
import {POSITION, TYPE} from 'vue-toastification'
import {UAParser} from 'ua-parser-js'
import Ad from "@/components/Ad";
import Sharer from "@/components/Sharer";

export default {
  name: "index",

  components: {Sharer, Ad, Cell, PhImageSquare, PhLinkSimple, PhHeadphones, PhHandWaving, PhShuffle, List},

  metaInfo() {
    return {
      title: this.bingoTitle,
      titleTemplate: '로빙고! | %s',
      link: [
        {rel: 'canonical', href: `https://ro-bingo.com/bingo/${parseInt(this.$route.params.id)}`}
      ],
      meta: [
        {
          property: 'og:title',
          content: this.bingoTitle,
          template: chunk => `로빙고! | ${chunk}`,
          vmid: 'og:title'
        },
        {
          property: 'twitter:title',
          content: this.bingoTitle,
          template: chunk => `로빙고! | ${chunk}`,
          vmid: 'twitter:title'
        },
        {
          property: 'og:url',
          content: parseInt(this.$route.params.id),
          template: chunk => `https://ro-bingo.com/bingo/${chunk}`,
          vmid: 'og:url'
        },
        {
          property: 'twitter:url',
          content: parseInt(this.$route.params.id),
          template: chunk => `https://ro-bingo.com/bingo/${chunk}`,
          vmid: 'twitter:url'
        },
        {
          property: 'description',
          content: this.bingoSubtitle,
          vmid: 'description'
        },
        {
          property: 'og:description',
          content: this.bingoSubtitle,
          vmid: 'og:description'
        },
        {
          property: 'twitter:description',
          content: this.bingoSubtitle,
          vmid: 'twitter:description'
        }
      ]
    }
  },

  data() {
    return {
      bingoTitle: undefined,
      bingoSubtitle: undefined,
      bingoElements: [],
      selectedCells: [],
      bingoRect: {},
      blob: null,
      downloadAvailable: true,
      bingoData
    }
  },

  methods: {
    loadBingo(id) {
      const data = find(bingoData, {id})
      this.bingoTitle = data.title
      this.bingoSubtitle = data.subtitle
      this.bingoElements = data.elements
      this.selectedCells = []
      this.blob = null
    },

    selectCell(cellIndex) {
      this.selectedCells.push(cellIndex)
    },

    unselectCell(cellIndex) {
      this.selectedCells = this.selectedCells.filter(value => value !== cellIndex)
    },

    bingoMatcher(type, number) {
      switch (type) {
        case 'row':
          return [5 * number, 5 * number + 1, 5 * number + 2, 5 * number + 3, 5 * number + 4]
        case 'col':
          return [number, number + 5, number + 10, number + 15, number + 20]
        case 'left':
          return [0, 6, 12, 18, 24]
        case 'right':
          return [4, 8, 12, 16, 20]
        default:
          return []
      }
    },

    convertDomToBlob() {
      const el = this.$refs.printArea
      const scale = 750 / el.offsetWidth

      domtoimage.toPng(el, {
        height: el.offsetHeight * scale,
        width: el.offsetWidth * scale,
        style: {
          transform: "scale(" + scale + ")",
          transformOrigin: "top left",
          width: el.offsetWidth + "px",
          height: el.offsetHeight + "px"
        }
      }).then(blob => this.blob = blob)
    },

    downloadImage() {
      this.$gtag.event('download-image', {'event_category': 'button'})
      FileSaver.saveAs(this.blob, 'myBingo.png')
      this.showToast('이미지가 저장되었어요 🏞')
    },

    copyUrlToClipBoard() {
      this.$gtag.event('copy-url', {'event_category': 'button'})
      const el = document.createElement('textarea')
      el.value = window.location.href
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.showToast('클립보드에 링크가 복사되었어요 😎')
    },

    showToast(msg) {
      this.$toast(msg, {
        type: TYPE.INFO,
        position: POSITION.BOTTOM_CENTER,
        timeout: 1000,
        transition: "Vue-Toastification__bounce",
        closeButton: false
      })
    },

    checkDownloadAvailable() {
      const parser = new UAParser()
      const result = parser.getResult()

      if (result.device.type === 'mobile') {
        const unavailableConditions = result.browser.name === 'Webkit'
            || result.browser.name === 'FACEBOOK'
            || includes(result.ua, "KAKAOTALK")

        if (unavailableConditions) {
          return false
        }

        switch (result.os.name) {
          case 'Android':
            return true
          case 'iOS':
            return result.browser.name === 'Mobile Safari'
          default:
            return false
        }
      } else {
        return true
      }
    },

    goToRandomBingo() {
      this.$gtag.event('load-random-bingo', {'event_category': 'button'})
      const id = sample(bingoData.filter(data => data.id !== parseInt(this.$route.params.id))).id
      window.location.href = this.$router.resolve({name: 'Bingo', params: {id}}).href
    },

    goToBye2020() {
      this.$gtag.event('link-2021', {'event_category': 'button'})
      window.location.href = `${window.location.origin}/2021`
    }
  },

  computed: {
    matchedRows() {
      return [0, 1, 2, 3, 4].filter(i => intersection(this.bingoMatcher('row', i), this.selectedCells).length === 5)
    },

    matchedCols() {
      return [0, 1, 2, 3, 4].filter(i => intersection(this.bingoMatcher('col', i), this.selectedCells).length === 5)
    },

    leftMatched() {
      return intersection(this.bingoMatcher('left'), this.selectedCells).length === 5
    },

    rightMatched() {
      return intersection(this.bingoMatcher('right'), this.selectedCells).length === 5
    },

    computedVars() {
      return {
        '--left-angle': `${this.bingoRect.angle}deg`,
        '--right-angle': `${-this.bingoRect.angle}deg`
      }
    },

    featuredBingo() {
      return shuffle(this.bingoData.filter(bingo => bingo.id !== parseInt(this.$route.params.id))).slice(0, 4)
    },

    newBingo() {
      return this.bingoData.filter(bingo => bingo.id === 22)
    }
  },

  watch: {
    '$route.params.id'() {
      this.loadBingo(parseInt(this.$route.params.id))
    },

    selectedCells() {
      this.convertDomToBlob()
    }
  },

  created() {
    this.loadBingo(parseInt(this.$route.params.id))
  },

  mounted() {
    const {x, y, width, height, top} = this.$refs.bingo.getBoundingClientRect()
    const diagonal = Math.round(Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)))
    const angle = Math.atan(height / width) * (180 / Math.PI)
    this.bingoRect = {x, y, width, height, top, diagonal, angle}
    this.convertDomToBlob()

    this.downloadAvailable = this.checkDownloadAvailable()
  }
}
</script>

<style scoped lang="scss">
.container {
  max-width: 500px;
  width: 100%;
  height: 100%;
  background: url("~@/assets/images/geometry2.png");
}

.print-area {
  background: url("~@/assets/images/geometry2.png");
}

.signature {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 0 4px 0 0;

  span {
    font-family: Bazzi;
    font-size: 20px;
    text-align: right;
  }
}

.title {
  width: 100%;
  padding: 20px 0;
  font-family: Yangjin;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: black;
}

.bingo {
  position: relative;
  overflow: hidden;
  padding: 4px 4px 20px 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  &--board {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 6;
    display: grid;
    grid-gap: 1vw;
    min-height: 403px;
    grid-template-rows: repeat(5, minmax(0, 1fr));
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  &--cell {
    display: grid;
    grid-template-areas: 'cell';
    cursor: pointer;

    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius: 15px 255px;
    border: dashed 2px #41403E;
    transition: ease-in-out .2s;
    -webkit-transition: ease-in-out .2s;

    &.active {
      border: solid 2px #41403E;
    }

    &:active {
      transform: skewY(-20deg);
      -webkit-transform: skewY(-20deg);
    }
  }

  &--row-line {
    justify-self: center;
    align-self: center;
    z-index: 200;
    width: 95%;
    border-radius: 20px;
    height: 20px;
    grid-column: 1 / 6;
    background-color: rgba(255, 255, 0, .3);
    pointer-events: none;

    &-0 {
      grid-row: 1 / 1;
    }

    &-1 {
      grid-row: 2 / 2;
    }

    &-2 {
      grid-row: 3 / 3;
    }

    &-3 {
      grid-row: 4 / 4;
    }

    &-4 {
      grid-row: 5 / 5;
    }
  }

  &--col-line {
    justify-self: center;
    align-self: center;
    z-index: 200;
    height: 95%;
    border-radius: 20px;
    width: 20px;
    grid-row: 1 / 6;
    background-color: rgba(255, 255, 0, .3);
    pointer-events: none;

    &-0 {
      grid-column: 1 / 1;
    }

    &-1 {
      grid-column: 2 / 2;
    }

    &-2 {
      grid-column: 3 / 3;
    }

    &-3 {
      grid-column: 4 / 4;
    }

    &-4 {
      grid-column: 5 / 5;
    }
  }

  &--diagonal-line {
    justify-self: center;
    align-self: center;
    z-index: 200;
    width: 125%;
    border-radius: 20px;
    height: 20px;
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 6;
    background-color: rgba(255, 255, 0, .3);
    pointer-events: none;


    &-left {
      transform: rotate(var(--left-angle));
    }

    &-right {
      transform: rotate(var(--right-angle));
    }
  }
}

.actions {
  display: flex;
  width: 100%;
  padding: 0 20px 20px 20px;

  .button {
    width: 100%;
    cursor: pointer;
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius: 15px 255px;
    border: solid 2px #41403E;
    color: rgba(0, 0, 0, .8);
    padding: 20px 5px;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'icon text';
    justify-items: center;
    align-items: center;
    transition: all .5s;
    -webkit-transition: all .5s;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:active {
      background-color: rgba(255, 255, 0, .7);
      transform: skewY(-20deg);
      -webkit-transform: skewY(-20deg);
    }

    svg {
      grid-area: icon;
    }

    span {
      grid-area: text;
      font-family: Bazzi;
      font-size: 14px;
    }
  }
}
</style>
