import Vue from 'vue'

import 'jquery'
import 'popper.js'
import 'bootstrap'

import BootstrapVue from "bootstrap-vue"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)