<template>
  <div class="cell-wrapper">
    <template v-for="(bingo, index) in bingoData">
      <div class="cell p-1"
           :key="`ad-position-${index}`"
           v-if="index > 1 && (index % adRepeat === 0) && adAvailable()"
           ontouchstart>
        <Ad :adContext="getAdContext()" style="padding-top: 10px"/>

      </div>
      <a class="cell"
         href="https://music.robin-seo.com"
         :key="`ext-link-position-${index}`"
         v-if="index === exposeExtLinkAt"
         ontouchstart>
        <h4>노래 들으러갈래? 🎧</h4>
        <h6>우주를 내게🧑‍🚀</h6>
      </a>
      <a class="cell"
         :key="`cell-${index}`"
         :href="$router.resolve({name: 'Bingo', params: {id: bingo.id}}).href"
         @click="$gtag.event('list-item-click', {'event_category': 'bingo-list', 'value': bingo.id})"
         ontouchstart>
        <h4>{{ bingo.title }}</h4>
        <h6>{{ bingo.subtitle }}</h6>
      </a>
    </template>
    <a
        class="cell text-right text-secondary"
        v-if="exposeMoreBtn"
        :href="$router.resolve({name: 'BingoList'}).href"
        @click="$gtag.event('bingo-more', {'event_category': 'bingo-list'})"
        ontouchstart>더 보러가기😎
    </a>
  </div>
</template>

<script>

import Ad from "@/components/Ad";

export default {
  name: "List",

  components: {Ad},

  props: {
    bingoData: Array,
    exposeMoreBtn: Boolean,
    adRepeat: {type: Number, default: 5},
    exposeExtLinkAt: {type: Number, default: 8}
  },

  data() {
    return {
      adCount: 0
    }
  },

  methods: {
    adAvailable() {
      return this.adContexts.length > 0
    },

    getAdContext() {
      const context = this.adContexts.shift()
      return context
    }
  },

  created() {
    this.adContexts = [
      {type: 'adFit', adUnit: 'DAN-nOHygQhptyv77CXn', width: 320, height: 100},
      {type: 'adFit', adUnit: 'DAN-6m4xflrHdhbb1UxQ', width: 320, height: 100},
      {type: 'adFit', adUnit: 'DAN-PQ1IvG9guKLayHfW', width: 320, height: 100},
    ]
  }
}
</script>

<style scoped lang="scss">
.cell {
  display: block;
  padding: 20px 20px;
  font-family: Bazzi;

  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  border-bottom-right-radius: 225px 15px;
  border-bottom-left-radius: 15px 255px;
  border-top: solid 2px #41403E;
  border-left: solid 2px #41403E;
  border-right: solid 2px #41403E;
  transition: ease-in-out .1s;
  -webkit-transition: ease-in-out .1s;
  background-color: rgba(255, 255, 255, .4);

  &:last-child {
    border-bottom: solid 2px #41403E;
  }

  h4 {
    color: black;
  }

  h6 {
    color: rgba(0, 0, 0, .7);
    text-decoration-line: underline;
  }

  &:active {
    border-bottom: solid 2px #41403E;
    background-color: rgba(255, 255, 0, .7);
    transform: skewX(-10deg) skewY(10deg);
    -webkit-transform: skewX(-10deg) skewY(10deg);
  }
}
</style>
